import React from 'react';
import Cart from 'Components/CartQuote/Cart';
import useScrollToTop from "Components/Hooks/useScrollToTop";

function CartPage() {
  useScrollToTop()
  return (
    <div className=''>
      <Cart/>
    </div>
  )
}

export default CartPage